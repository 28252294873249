import axios from "axios";

const sericve = axios.create({
  timeout: 10000,
  baseURL: "https://happynewyear.cestalt.com",
});

/**
 * 请求之前
 */
sericve.interceptors.request.use(
  (config) => {
    if (localStorage.getItem("token")) {
      config.headers["x-token"] = localStorage.getItem("token");
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);
/**
 * 请求之后
 */
sericve.interceptors.response.use(
  function(response) {
    // 状态码200
    if (response.status === 200) {
      return response.data;
    }
  },
  function(error) {
    return Promise.reject(error);
  }
);

/**
 * 导出
 */
export default sericve;
