import axios from "axios";
import request from "@/utils/request";

/**
 * 用户登陆
 */
export const login = (data) => {
  return request({
    method: "post",
    url: "/api/lottery/system/login",
    data,
  });
};

// 获取奖品列表、未中奖用户列表
export const getLotteryUsers = (data) => {
  return request({
    method: "post",
    url: "/api/lottery/lotteryUsers",
    data,
  });
};

// 获取中奖用户列表
export const getFindWinning = (data) => {
  return request({
    method: "get",
    url: "/api/lottery/findWinning",
    data,
  });
};

// 重置抽奖
export const reset = () => {
  return request({
    method: "get",
    url: "/api/lottery/reset",
  });
};

// 抽奖
export const lottery = (data) => {
  return request({
    method: "post",
    url: "/api/lottery/lottery",
    data,
  });
};
