import Vue from "vue";
import Vuex from "vuex";
import { getLotteryUsers } from "@/api/main.js";

Vue.use(Vuex);

export default new Vuex.Store({
  state: {
    lotteryUsers: {}, // 奖品与用户的对象
    currentPrizeName: "", // 当前奖品
  },
  mutations: {
    SET_LOTTERY_USERS: (state, data) => {
      state.lotteryUsers = data;
    },
    SET_CURRENT_PRIZE_NAME: (state, data) => {
      state.currentPrizeName = data;
    },
  },
  actions: {
    async getLotteryUsers({ commit }) {
      let res = await getLotteryUsers();
      if (res.code == 200) {
        commit("SET_LOTTERY_USERS", res.msg);
      } else if (res.code == 2002) {
        // 身份过期，清除local中的token，即可进入登录页面
        localStorage.clear();
      }
    },
  },
  modules: {},
});
